/* The button */
.addeventatc {
  display: inline-block;
  position: relative;
  font-family: "Open Sans", Roboto, Optima, Segoe, "Segoe UI", Candara, Calibri,
    Arial, sans-serif;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.addeventatc:hover {
  color: #555;
  font-size: 16px;
  text-decoration: underline;
}
.addeventatc .start,
.addeventatc .end,
.addeventatc .timezone,
.addeventatc .title,
.addeventatc .description,
.addeventatc .location,
.addeventatc .organizer,
.addeventatc .organizer_email,
.addeventatc .facebook_event,
.addeventatc .all_day_event,
.addeventatc .date_format,
.addeventatc .alarm_reminder,
.addeventatc .recurring,
.addeventatc .attendees,
.addeventatc .calname,
.addeventatc .uid,
.addeventatc .status,
.addeventatc .method,
.addeventatc .client,
.addeventatc .transp {
  display: none !important;
}

/* Drop down */
.addeventatc_dropdown {
  width: 230px;
  position: absolute;
  padding: 6px 0px 0px 0px;
  font-family: "Open Sans", Roboto, "Helvetica Neue", Helvetica, Optima, Segoe,
    "Segoe UI", Candara, Calibri, Arial, sans-serif;
  color: #000 !important;
  font-weight: 600;
  line-height: 100%;
  background: #fff;
  font-size: 15px;
  text-decoration: none;
  text-align: left;
  margin-left: -1px;
  display: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  transform: scale(0.98, 0.98) translateY(5px);
  z-index: -1;
  transition: transform 0.15s ease;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.addeventatc_dropdown.topdown {
  transform: scale(0.98, 0.98) translateY(-5px) !important;
}
.addeventatc_dropdown span {
  display: block;
  line-height: 100%;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  color: #333;
  font-weight: 600;
  padding: 14px 10px 14px 55px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.addeventatc_dropdown span:hover {
  background-color: #f4f4f4;
  color: #000;
  text-decoration: none;
  font-size: 15px;
}
.addeventatc_dropdown em {
  color: #999 !important;
  font-size: 12px !important;
  font-weight: 400;
}
.addeventatc_dropdown .frs a {
  background: #fff;
  color: #cacaca !important;
  cursor: pointer;
  font-size: 9px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 110% !important;
  padding-left: 10px;
  position: absolute;
  right: 10px;
  text-align: right;
  text-decoration: none;
  top: 5px;
  z-index: 101;
}
.addeventatc_dropdown .frs a:hover {
  color: #999 !important;
}
.addeventatc_dropdown .ateappleical {
  background: url(/global/icon-apple-t5.svg) 18px 40% no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ategoogle {
  background: url(/global/icon-google-t5.svg) 18px 50% no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateoutlook {
  background: url(/global/icon-outlook-t5.svg) 18px 50% no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateoutlookcom {
  background: url(/global/icon-outlookcom-t5.svg) 18px 50% no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateyahoo {
  background: url(/global/icon-yahoo-t5.svg) 18px 50% no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .atefacebook {
  background: url(/global/icon-facebook-t5.svg) 18px 50% no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .copyx {
  height: 21px;
  display: block;
  position: relative;
  cursor: default;
}
.addeventatc_dropdown .brx {
  height: 1px;
  overflow: hidden;
  background: #e8e8e8;
  position: absolute;
  z-index: 100;
  left: 10px;
  right: 10px;
  top: 9px;
}
.addeventatc_dropdown.addeventatc-selected {
  transform: scale(1, 1) translateY(0px);
  z-index: 99999999999;
}
.addeventatc_dropdown.topdown.addeventatc-selected {
  transform: scale(1, 1) translateY(0px) !important;
}
.addeventatc_dropdown .drop_markup {
  background-color: #f4f4f4;
}

////////////////
.addeventatc {
  margin-top: 10px;
  @include font-franklin-gothic-book();
  color: #fff;
  background-color: transparent;
  cursor: default;
  .clicker-text {
    @include transition_all();
    letter-spacing: 0.05em;
  }
  .addeventatc_icon {
    background-image: url("/global/Add-to-Calendar.svg") !important;
    background-repeat: no-repeat !important;
  }
  .addeventatc_dropdown {
    background-color: #000;
    @include font-franklin-gothic-book();
    letter-spacing: 0.05em;
    border: 4px solid #2b2b2b;
    border-radius: 0;
    padding: 0;
    span {
      color: #fff;
      @include transition_all();
      &:hover {
        background-color: #2b2b2b;
      }
    }
    .copyx {
      display: none !important;
    }
  }
  &:hover {
    text-decoration: none;
    .clicker-text {
      color: #fff;
      letter-spacing: 0.13em;
    }
  }
}
