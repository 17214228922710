$breakTightNavItems: 1200px;

#film-nav-gradient {
  display: none;
}
#film-nav.mobile {
  position: relative;
  //top:$header-height;
  top: 50px;
  left: 30px;
  width: 100%;
  z-index: 101;
  @include transition_all();
  display: none;

  @media only screen and (max-width: $film-nav-breakpoint) {
    display: block;
    position: fixed;
    top: 50px;
    height: auto;
    background-color: #4e80c1;
    left: 0;
  }

  #film-nav2 {
    top: 10px;
    //@include master-margin-left-right-width();
    margin-left: 0;
    width: 100%;
    @media only screen and (max-width: $film-nav-breakpoint) {
      top: 0;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
  nav {
    margin-bottom: 80px;
    .mobile-nav-arrow {
      display: none;
      @media only screen and (max-width: $film-nav-breakpoint) {
        display: block;
        position: absolute;
        top: 14px;
        right: 10px;

        .material-icons {
          color: #fff;
          font-size: 30px;
          svg {
            width: 18px;
            fill: white;
          }
        }
        .down {
          display: block;
        }
        .up {
          display: none;
        }
      }
    }
    .items {
      display: block;
      justify-content: space-between;
      @media only screen and (max-width: $film-nav-breakpoint) {
        display: block;
        padding: 5px 0;
      }
      .mobile-clicker {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        @media only screen and (max-width: $film-nav-breakpoint) {
          display: block;
        }
      }
    }
    .item {
      flex: 0 1 auto;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
      }
      .item2 {
        display: flex;
        text-align: left;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }

        @media only screen and (max-width: $film-nav-breakpoint) {
          display: flex;
          text-align: left;
          align-items: center;
          padding: 5px 0;
        }

        .line,
        .txt {
          flex: 0 1 auto;
        }

        .txt {
          @include font-franklin-gothic-medium();
          color: $color-blue-lt2;
          font-size: 20px;
          text-transform: uppercase;
          left: -30px;
          @include transition_all();
          @media only screen and (max-width: $film-nav-breakpoint) {
            margin: 10px 0 0 20px;
            font-size: 20px;
            left: 0;
          }
        }
      }

      .line {
        left: -30px;
        height: 10px;
        overflow: hidden;
        .line2 {
          position: absolute;
          top: 8px;
          left: -5px;
          display: block;
          width: 0;
          height: 2px;
          background-color: $color-blue-lt2;
          @include transition_all();
        }
        @media only screen and (max-width: $film-nav-breakpoint) {
          display: none;
        }
      }

      &:hover,
      &.selected {
        .txt {
          color: #fff;
        }
        .line {
          .line2 {
            width: 100%;
          }
        }
      }
      &:hover {
      }
    }

    //super cool overrides
    @media only screen and (max-width: $film-nav-breakpoint) {
      margin-bottom: 0;

      overflow: hidden;
      height: 55px;

      .item {
        // display: none;
        &.selected {
          display: block;
        }
        &.unselected {
          display: none;
        }
      }
      &.open {
        @include calc(height, "100% - " + $mobile-header-height);
        overflow: auto;

        .mobile-nav-arrow {
          .down {
            display: none;
          }
          .up {
            display: block;
          }
        }

        .items {
          .mobile-clicker {
            display: none;
          }
        }

        .item {
          display: block;
          &.selected {
            display: block;
          }
        }
      }
    }
  }
}
