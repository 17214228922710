
//LEAVE AT BOTTOM - desktop, mobile global hiders
.show-desktop {
  display: inline-block;
  &.block {
    display: block;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: none;
    &.block {
      display: none;
    }
  }
}
.show-mobile {
  display: none;
  &.block {
    display: none;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: inline-block;
    &.block {
      display: block;
    }
  }
}





//COOKIE
.evidon-banner {
  position: fixed!important;
  top:0!important;
  //bottom:0!important;
  .evidon-banner-message {
    @include font-futura-medium();
    * {
      @include font-futura-medium();
    }
  }
}

#onetrust-consent-sdk {
  font-family: 'Conv_FuturaStd-Medium', sans-serif!important;
}
#onetrust-policy-text {
  text-align: center!important;
  margin: 0 auto!important;
  max-width: 1200px!important;
  float: none!important;
  * {
    font-family: 'Conv_FuturaStd-Medium', sans-serif!important;
    letter-spacing: 0.02em!important;
    text-align: center!important;
  }
  font-family: 'Conv_FuturaStd-Medium', sans-serif!important;
  letter-spacing: 0.1em!important;
}

#onetrust-banner-sdk {
  opacity: 0;
}