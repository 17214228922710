@import "../../__build/node_modules/aos/dist/aos.css";
@import "../../__build/node_modules/swiper/swiper-bundle.min.css";

@import "../fout/fout.scss";
@import "scss/engine/loader";
@import "scss/site/loader";

//HIDES
//HIDE mailchimp - also commented out via PHP
// .footer .l1,
header.header .r .item.sign-up {
  display: none;
}
header.header .r {
  justify-content: flex-end;
}
