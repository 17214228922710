.film-block.videos {
  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 80px;
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
    }
    .item {
      cursor: pointer;
      .poster {
        width:100%;
        height: 0;
        padding-top: 56%;
        .bgimg {
          @include transition-all();
        }
      }
      .label {
        margin-top: 30px;
        @include font-oswald-medium();
        color:#fff;
        font-size: 30px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          line-height: 1em;
        }
        svg {
          @include transition-all();
          top:1px;
          margin-left: 10px;
          width: 32px;
          path {
            fill:#fff;
          }
        }
      }
      &:hover {
        .poster {
          .bgimg {
            opacity: 0.8;
          }
        }
        .label {
          svg {
            transform: translate(5px);
          }
        }
      }
    }
  }
}

