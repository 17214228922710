$mobilenav_w: 100%;
$mobilenav_hamburger_off: #fff;
$mobilenav_hamburger_on: #fff;
$mobilenav_bg_off: #fff;
$mobilenav_bg_on: #fff;
$mobilenav_font_off: #fff;
$mobilenav_font_on: #fff;

@media only screen and (min-width: $mobile-nav-breakpoint-plus-1) {
  #mobile-nav-handle,
  #mobile-nav {
    display: none !important;
  }
}
@media only screen and (max-width: $mobile-nav-breakpoint) {
  #mobile-nav-handle {
    display: block;
  }
}

//handle
#mobile-nav-handle {
  position: fixed;
  width: 75px;
  height: 20px;
  cursor: pointer;
  z-index: 200001;
  top: 10px;
  right: 15px;
  .inner {
    padding: 5px;
  }
  @media only screen and (max-width: $specialHeaderBreakTiny) {
    //width:50px;
    //top:3px;
  }
  #mobile-nav-label {
    .lbl {
      @include font-franklin-gothic-demibold();
      color: $color-blue-lt2;
      font-size: 18px;
      line-height: 18px;
      text-decoration: none;
      text-transform: uppercase;
      @media only screen and (max-width: $specialHeaderBreakTiny) {
        //font-size: 12px;
        //line-height: 12px;
        //top:2px;
      }
    }

    @include transition_all();
    position: absolute;
    top: 8px;
    left: -25px;
    width: 75px;
    text-align: right;
    .open {
      display: none;
    }
    .closed {
      display: block;
    }
    @media only screen and (max-width: $specialHeaderBreakTiny) {
      //width:50px;
    }
  }
  &.is-active {
    #mobile-nav-label {
      .open {
        display: block;
      }
      .closed {
        display: none;
      }
    }
  }
  #mobile-nav-hamburger {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 2;
    .stroke {
      width: 20px;
      background-color: $mobilenav_hamburger_off;
      border-radius: 0;
      left: 0;
      height: 3px;
      @include transition_all();
      &.stroke1 {
        top: 0;
      }
      &.stroke2 {
        top: 5px;
      }
    }
    .hamburger-btn {
      @include transition_all();
      top: 0;
      &.is-active {
        top: -5px;

        .stroke {
          background-color: $mobilenav_hamburger_on;

          &.stroke1 {
            top: 9px;
            transform: rotate(-45deg);
          }

          &.stroke2 {
            top: 6px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  @include transition_all();
  &:hover {
    opacity: 0.85;
  }
}

//nav
#mobile-nav {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000;
}
#mobile-nav-overlay {
  //background-color: #000;
  background: #c3dcfd;
  background: linear-gradient(180deg, $color-blue-md 0%, #133d74 100%);

  width: $mobilenav_w;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  @include transition_all();
  padding-top: 0;
  display: block;
  overflow: hidden;

  &.hidden {
    left: -$mobilenav_w;
    display: none;
  }

  .logo {
    position: absolute;
    top: 19px;
    left: 15px;
    img {
      width: 150px;
      @include transition_all();
    }
  }
  .mobile-flex {
    display: flex;
    height: 100%;
    min-height: 300px;
    flex-direction: column;
    justify-content: space-between;
  }

  //CONTENT
  #mobile-nav-content {
    flex: 0 1 auto;
    display: block;
    width: 100%;
    padding: 80px 40px 20px;
    @include calc(width, "100% - 80px");

    h2 {
      @include font-futura-book();
      font-size: 11px;
      color: $color-blue-lt2;
      text-transform: uppercase;
      margin-bottom: 15px;
      letter-spacing: 0.4em;
    }
    ul,
    li {
      list-style: none;
    }
    li {
      margin: 0 0 20px;
      a {
        @include font-franklin-gothic-medium();
        font-size: 20px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
      }
    }
    .line-screenings {
      margin-top: 40px;
    }
  }

  #mobile-nav-footer {
    flex: 0 1 auto;
    .contact {
      padding-bottom: 20px;
    }
  }

  //content
  #mobile-nav-content {
    overflow: hidden;
  }

  @media screen and (max-height: 570px) {
    #mobile-nav-content {
      padding: 70px 40px 20px;
      li {
        margin-bottom: 10px;
      }
      .line-screenings {
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-height: 510px) {
    #mobile-nav-content {
      li {
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (max-height: 450px) {
    #mobile-nav-footer {
      display: none;
    }
  }
}

.device_nottabmobile #mobile-nav-overlay #mobile-nav-content li a {
  @include transition_all();
  &:hover {
    letter-spacing: 0.13em;
  }
}
