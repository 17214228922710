//SC - 4-20-2020

//BREAKS
$breakpoint-tiny: 370px;
$breakpoint-smaller: 500px;
$breakpoint-small: 900px;
$breakpoint-small-plus-1: 901px;
$breakpoint-medium: 1400px;
$breakpoint-medium-plus-1: 1401px;
$breakpoint-mobile-nav: 940px;

//CAPS
$max-width-site: 1920px;
$max-width-site-plus-1: 1921px;
$max-width-copy: 900px;

//PADDINGS
$master-padding: 100px;
$master-padding-2x: 200px;
$master-padding-4x: 400px;
$master-padding-medium: 80px;
$master-padding-medium-2x: 160px;
$master-padding-medium-4x: 320px;
$master-padding-mobile: 20px;
$master-padding-mobile-2x: 40px;
$master-padding-mobile-4x: 80px;
$master-padding-tiny: 10px;
$master-padding-tiny-2x: 20px;
$master-padding-tiny-4x: 40px;

$master-padding-tb: 40px;
$master-padding-tb-medium: 30px;
$master-padding-tb-mobile: 20px;
$master-padding-tb-tiny: 15px;

//MIXINS
@mixin master-padding-lr() {
  padding-left: $master-padding;
  padding-right: $master-padding;
  @media only screen and (max-width: $breakpoint-medium) {
    padding-left: $master-padding-medium;
    padding-right: $master-padding-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    padding-left: $master-padding-mobile;
    padding-right: $master-padding-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    padding-left: $master-padding-tiny;
    padding-right: $master-padding-tiny;
  }
}
@mixin master-padding-tb() {
  padding-top: $master-padding-tb;
  padding-bottom: $master-padding-tb;
  @media only screen and (max-width: $breakpoint-medium) {
    padding-top: $master-padding-tb-medium;
    padding-bottom: $master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    padding-top: $master-padding-tb-mobile;
    padding-bottom: $master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    padding-top: $master-padding-tb-tiny;
    padding-bottom: $master-padding-tb-tiny;
  }
}
@mixin master-padding-t() {
  padding-top: $master-tb-padding;
  @media only screen and (max-width: $breakpoint-medium) {
    padding-top: $master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    padding-top: $master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    padding-top: $master-padding-tb-tiny;
  }
}
@mixin master-padding-b() {
  padding-bottom: $master-padding-tb;
  @media only screen and (max-width: $breakpoint-medium) {
    padding-bottom: $master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    padding-bottom: $master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    padding-bottom: $master-padding-tb-tiny;
  }
}

@mixin master-padding-lr-width() {
  box-sizing: initial !important;
  @include calc(width, "100% - " + $master-padding-2x);
  padding-left: $master-padding;
  padding-right: $master-padding;
  @media only screen and (max-width: $breakpoint-medium) {
    @include calc(width, "100% - " + $master-padding-medium-2x);
    padding-left: $master-padding-medium;
    padding-right: $master-padding-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    @include calc(width, "100% - " + $master-padding-mobile-2x);
    padding-left: $master-padding-mobile;
    padding-right: $master-padding-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    @include calc(width, "100% - " + $master-padding-tiny-2x);
    padding-left: $master-padding-tiny;
    padding-right: $master-padding-tiny;
  }
}
@mixin master-padding-lr-width-2x() {
  box-sizing: initial !important;
  @include calc(width, "100% - " + $master-padding-4x);
  padding-left: $master-padding-2x;
  padding-right: $master-padding-2x;
  @media only screen and (max-width: $breakpoint-medium) {
    @include calc(width, "100% - " + $master-padding-medium-4x);
    padding-left: $master-padding-medium-2x;
    padding-right: $master-padding-medium-2x;
  }
  @media only screen and (max-width: $breakpoint-small) {
    @include calc(width, "100% - " + $master-padding-mobile-4x);
    padding-left: $master-padding-mobile-2x;
    padding-right: $master-padding-mobile-2x;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    @include calc(width, "100% - " + $master-padding-tiny-4x);
    padding-left: $master-padding-tiny-2x;
    padding-right: $master-padding-tiny-2x;
  }
}

@mixin master-negative-left-margin() {
  margin-left: -$master-padding;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-left: -$master-padding-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    margin-left: -$master-padding-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    margin-left: -$master-padding-tiny;
  }
}

@mixin master-position-left() {
  left: $master-padding;
  @media only screen and (max-width: $breakpoint-medium) {
    left: $master-padding-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    left: $master-padding-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    left: $master-padding-tiny;
  }
}

@mixin master-margin-lr() {
  margin-left: $master-padding;
  margin-right: $master-padding;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-left: $master-padding-medium;
    margin-right: $master-padding-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    margin-left: $master-padding-mobile;
    margin-right: $master-padding-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    margin-left: $master-padding-tiny;
    margin-right: $master-padding-tiny;
  }
}
@mixin master-margin-tb() {
  margin-top: $master-padding-tb;
  margin-bottom: $master-padding-tb;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: $master-padding-tb-medium;
    margin-bottom: $master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $master-padding-tb-mobile;
    margin-bottom: $master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    margin-top: $master-padding-tb-tiny;
    margin-bottom: $master-padding-tb-tiny;
  }
}
@mixin master-margin-t() {
  margin-top: $master-padding-tb;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: $master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    margin-top: $master-padding-tb-tiny;
  }
}
@mixin master-margin-b() {
  margin-bottom: $master-padding-tb;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-bottom: $master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    margin-bottom: $master-padding-tb-tiny;
  }
}

//GLOBAL CLASSES
.capper {
  width: 100%;
  max-width: $max-width-site;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: $breakpoint-small) {
    width: 90%;
  }
  &.wh {
    width: 100%;
    height: 100%;
  }
}
.capper-padded {
  @include master-padding-lr-width();
  &.no-mobile-pad {
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
.capper-padded-2x {
  @include master-padding-lr-width-2x();
}

@mixin blocks-margin-tb() {
  $blocksMargin: 10px;
  margin-top: $blocksMargin;
  margin-bottom: $blocksMargin;
  /*
  @media only screen and (max-width: $breakpoint-medium) {
    margin-top:$master-padding-tb-medium;
    margin-bottom:$master-padding-tb-medium;
  }
  @media only screen and (max-width: $breakpoint-small) {
    margin-top:$master-padding-tb-mobile;
    margin-bottom:$master-padding-tb-mobile;
  }
  @media only screen and (max-width: $breakpoint-tiny) {
    margin-top:$master-padding-tb-tiny;
    margin-bottom:$master-padding-tb-tiny;
  }

   */
}
