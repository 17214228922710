.footer {
  @include master-min-margins();
  padding-top: 20px;
  padding-bottom: 15px;
  .contact {
    padding-bottom: 7px;
    @media only screen and (max-width: $footer-breakpoint) {
      text-align: center;
    }
    .l1 {
      color: $color-blue-lt;
      @include font-futura-bold();
      font-size: 19px;
      padding-bottom: 8px;
    }
    .l2 {
      a,
      .seperator {
        color: $color-blue-lt;
        @include font-futura-medium();
        font-size: 15px;
        text-decoration: none;
        word-break: break-all;
      }
      .seperator {
        padding: 0 10px;
        @media only screen and (max-width: $tiny-breakpoint) {
          display: block;
          opacity: 0;
          height: 6px;
          overflow: hidden;
        }
      }
      a {
        @include transition_all();
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .legal {
    display: flex;
    justify-content: space-between;
    .l,
    .r {
      flex: 0 1 auto;
    }
    @media only screen and (max-width: $footer-breakpoint) {
      display: block;
      text-align: center;
      .l {
        margin-bottom: 10px;
      }
    }

    .txt {
      @include font-futura-medium();
      color: $color-blue-vlt;
      font-size: 12px;
    }
    ul,
    li {
      list-style: none;
    }
    li {
      display: inline;
      padding-left: 20px;
      &:first-child {
        padding-left: 0;
      }
      @media only screen and (max-width: $small-breakpoint) {
        padding: 0 10px !important;
      }
      margin-bottom: 5px;
    }
    a {
      @include transition_all();
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: $small-breakpoint) {
  }
}
