$filmsBreak: $small-breakpoint;
#home {
  .sunset-description {
    text-align: center;
    margin-top: 50px;
    .rte {
      margin: 0 auto;
    }
  }
  .home-download {
    display: block;
    text-align: right;
    left: -30px;
    @include master-min-margins();
    padding-top: 10px;
    @media only screen and (max-width: $mobile-nav-breakpoint) {
      padding-top: 0;
    }
    @media only screen and (max-width: $small-breakpoint) {
      text-align: center;
      left: -10px;
    }
    @media only screen and (max-width: $tiny-breakpoint) {
      padding-top: 0;
    }

    a {
      @include font-franklin-gothic-demibold();
      color: $color-blue-lt2;
      font-size: 13px;
      text-decoration: none;
      text-transform: uppercase;
      @include transition_all();

      &:hover {
        color: #fff;
      }

      .icon {
        position: absolute;
        top: -27px;
        right: -30px;
        svg {
          width: 17px;
          line,
          path {
            @include transition_all();
          }
          line {
            stroke: #fff;
          }
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .home-header {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    h1 {
      // color:#fff;
      color: $color-blue-lt2;

      body.awards & {
        color: $color-blue-lt2;
      }
      @include font-franklin-gothic-demibold();
      font-size: 78px;
      line-height: 68px;
      font-weight: normal;
      letter-spacing: 0.13em;
      margin: 40px 0 0 0;
      @media only screen and (max-width: $small-breakpoint) {
        font-size: 8.9vw; //58
        line-height: 8vw; //50
        margin-top: 20px;
      }
    }
    h2 {
      // color: #fff;
      color: $color-blue-lt2;
      body.awards & {
        color: $color-blue-lt2;
      }
      @include font-futura-book();
      font-size: 31px;
      font-weight: normal;
      letter-spacing: 0.52em;
      @media only screen and (max-width: 1000px) {
        letter-spacing: 0.32em;
      }
      @media only screen and (max-width: $small-breakpoint) {
        font-size: 3vw; //20
        letter-spacing: 0.6em;
      }
    }
  }

  .films {
    margin-top: 50px;
    $filmsMargin: 15px;

    @extend .master-capper;
    .filmlist {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      justify-content: space-around;
      width: 90%;
      margin-left: 5%;
      @media only screen and (max-width: $filmsBreak) {
        display: block;
      }

      .entry {
        flex: 0 1 auto;
        width: 27%;
        margin-bottom: 0;
        text-align: center;
        padding: $filmsMargin $filmsMargin $filmsMargin $filmsMargin;

        @media only screen and (max-width: $filmsBreak) {
          width: 60%;
          margin: 0 auto;
        }

        .entry-inner {
          width: 100%;
          height: 0;
          padding-top: 144.8%;

          .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            top: 0;
            left: 0;
            display: block;

            @include transition_all();

            //background-color: rgba(0, 0, 0, 0.4);
            //background-color: rgba(255, 255, 255, 0.35);
            body.awards & {
              //background-color: rgba(255, 255, 255, 0.35);
            }

            .txt-title {
              @include font-franklin-gothic-medium();
              color: #fff;
              font-size: 30px;
              padding: 10px;
              @include calc(width, "100% - 20px");
              @include transition_all();

              body.awards & {
                color: #fff;
              }
            }
            .bgimg {
              @include transition_all();
              background-size: contain;

              body.awards & {
                background-size: contain;
                margin: 20px;
                @include calc(width, "100% - 40px");
                @include calc(height, "100% - 40px");
              }

              &.homett {
                background-size: contain;
                margin: 20px;
                @include calc(width, "100% - 40px");
                @include calc(height, "100% - 40px");
              }
            }
          }
        }

        .home-arrow {
          visibility: hidden;
          opacity: 0;
          width: 60px;
          height: 60px;
          border-radius: 35px;
          border: 3px solid $color-blue-vlt;
          background-color: $color-blue-dk;
          text-align: center;
          cursor: pointer;
          margin-top: -34px;
          @include calc("left", "50% - 33px");
          @include transition_all();
          svg {
            width: 28px;
            height: 28px;
            top: 2px;
          }
          body.awards & {
            display: none;
          }
        }

        a {
          text-decoration: none;
          cursor: pointer;
          &:hover {
            .home-arrow {
              visibility: visible;
              opacity: 1;
            }
            .entry-inner {
              .bg {
                //background-color: rgba(0, 0, 0, 0.85);
                //background-color: rgba(255, 255, 255, 0.15);
                .bgimg {
                  opacity: 1;
                }
                .txt-title {
                }
                body.awards & {
                  //background-color: rgba(255, 255, 255, 0.15);
                }
              }
            }
          }
          &.growhover {
            &:hover {
              .entry-inner {
                .bg {
                  .bgimg {
                    transform: scale(1.03);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.awards #home {
  .films {
    .filmlist {
      width: 80%;
      margin: 0 auto;
      justify-content: center;
      .entry {
        width: 40%;
        @media only screen and (max-width: $filmsBreak) {
          width: 90%;
        }
        .entry-inner {
          padding-top: 50%;
          @media only screen and (max-width: $filmsBreak) {
            padding-top: 50%;
          }
        }
      }
    }
  }
}
