.film-block.gallery {
  .items {
    width:100%;
    .swiper-wrapper, .swiper-slide {
      width:100%;
    }
    .swiper-slide {
      cursor: pointer;
    }
  }

  .gallery-big {
    width:100%;
    aspect-ratio: 16 / 9;
  }

  .gallery-thumbs-outer {
    width: calc(100% - 160px);
    height: 100px;
    margin-left: 80px;

    margin-top: 60px;
    @media only screen and (max-width: 1800px) {margin-top: 45px;}
    @media only screen and (max-width: 1700px) {margin-top: 40px;}
    @media only screen and (max-width: 1600px) {margin-top: 35px;}
    @media only screen and (max-width: 1500px) {margin-top: 30px;}
    @media only screen and (max-width: 1400px) {margin-top: 25px;}
    @media only screen and (max-width: 1300px) {margin-top: 20px;}
    @media only screen and (max-width: 1200px) {margin-top: 15px;}
    @media only screen and (max-width: 1100px) {margin-top: 10px;}
    @media only screen and (max-width: 1000px) {margin-top: 5px;}
    @media only screen and (max-width: $breakpoint-small) {margin-top: 60px;}
    @media only screen and (max-width: 700px) {margin-top: 40px;}
    @media only screen and (max-width: 500px) {margin-top: 20px;}
    @media only screen and (max-width: 400px) {margin-top: 0;}

    .swiper-slide {
      display: flex;
      align-items: center;
      .bgimg {
        position: relative;
        height: auto;
        aspect-ratio: 16 / 9;
      }
    }
    .swiper-button-next, .swiper-button-prev {
      &:after {
        display: none;
      }
      svg {
        width: 100%;
        path {
          fill:#fff;
        }
      }
      @include transition-all();

    }
    .swiper-button-next {
      right:-80px;
      &:hover {
        transform: translateX(5px);
      }
    }
    .swiper-button-prev {
      left:-80px;
      &:hover {
        transform: translateX(-5px);
      }
    }
  }
  .gallery-thumbs {
    width:100%;
    height:100%;
    @include transition-opacity();
    .swiper-slide {
      opacity:0.4;
    }
    .swiper-slide-active {
      opacity:1;
    }
  }
}

