// /////////////////////////////////////////////////////////
// //GLOBAL TAGS
// /////////////////////////////////////////////////////////
// html, body {
//   background-color: #000;
//   scroll-behavior: smooth;
// }

// body {
//   @include vh100();
// }

// #container {
//   width: 100%;
// }

// #content {
//   @include vh100();
// }

// #meta-sitewidth {
//   display: none;
//   z-index: 0;
//   padding-top: $max-width-site;
// }
// #meta-breakpoint {
//   display: none;
//   z-index: 0;
//   padding-top: $breakpoint-small;
// }

// a {
//   text-decoration: none;
// }

// .generic-holder {
//   @include master-padding-tb();
//   min-height: 300px;

//   .generic-holder-content {
//     padding-top: 40px;
//   }
// }

// .blocks-holder {
//   width: 100%;
// }
// .block {
//   width:100%;
//   @include blocks-margin-tb();
// }

/////////////////////////////////////////////////////////
//FONTS
/////////////////////////////////////////////////////////
@font-face {
  font-family: "Conv_ITCFranklinGothicStd-DmCd";
  src: url("../fonts/ITCFranklinGothicStd-DmCd.eot");
  src: local("☺"), url("../fonts/ITCFranklinGothicStd-DmCd.woff") format("woff"),
    url("../fonts/ITCFranklinGothicStd-DmCd.ttf") format("truetype"),
    url("../fonts/ITCFranklinGothicStd-DmCd.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_ITCFranklinGothicStd-MdCd";
  src: url("../fonts/ITCFranklinGothicStd-MdCd.eot");
  src: local("☺"), url("../fonts/ITCFranklinGothicStd-MdCd.woff") format("woff"),
    url("../fonts/ITCFranklinGothicStd-MdCd.ttf") format("truetype"),
    url("../fonts/ITCFranklinGothicStd-MdCd.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_ITCFranklinGothicStd-BkCd";
  src: url("../fonts/ITCFranklinGothicStd-BkCd.eot");
  src: local("☺"), url("../fonts/ITCFranklinGothicStd-BkCd.woff") format("woff"),
    url("../fonts/ITCFranklinGothicStd-BkCd.ttf") format("truetype"),
    url("../fonts/ITCFranklinGothicStd-BkCd.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Conv_FuturaStd-Bold";
  src: url("../fonts/FuturaStd-Bold.eot");
  src: local("☺"), url("../fonts/FuturaStd-Bold.woff") format("woff"),
    url("../fonts/FuturaStd-Bold.ttf") format("truetype"),
    url("../fonts/FuturaStd-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Conv_FuturaStd-Book";
  src: url("../fonts/FuturaStd-Book.eot");
  src: local("☺"), url("../fonts/FuturaStd-Book.woff") format("woff"),
    url("../fonts/FuturaStd-Book.ttf") format("truetype"),
    url("../fonts/FuturaStd-Book.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_FuturaStd-Medium";
  src: url("../fonts/FuturaStd-Medium.eot");
  src: local("☺"), url("../fonts/FuturaStd-Medium.woff") format("woff"),
    url("../fonts/FuturaStd-Medium.ttf") format("truetype"),
    url("../fonts/FuturaStd-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/////////////////////////////////////////////////////////
//GLOBAL TAGS
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
//DEFAULTS
/////////////////////////////////////////////////////////
* {
}

html,
body {
  width: 100%;
  background-color: $color-blue-md;

  // overflow-x: hidden;
  &.home {
    background-color: #000;
  }
}
#bg {
  display: block;
}
body.film #bg,
body.screenings #bg {
  display: none;
}
#bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgb(65, 115, 180);
  background: linear-gradient(
    180deg,
    rgba(65, 115, 180, 1) 0%,
    rgba(28, 75, 124, 1) 100%
  );
  background: linear-gradient(180deg, #4173b4 0, #1c4b7c 100%);
}
.main-zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footer {
    flex: 0 1 auto;
  }
  .main-content {
    flex: 0 1 auto;
    //@include calc(min-height,'100vh - 280px');
    @include calc(min-height, "100vh - 184px");
    @media only screen and (max-width: $footer-breakpoint) {
      //@include calc(min-height,'100vh - 240px');
      @include calc(min-height, "100vh - 164px");
    }

    @include transition_all();
    will-change: unset;
    padding-top: $header-height;
    @media only screen and (max-width: $tiny-breakpoint) {
      padding-top: 50px;
    }
  }
}

.miniheader {
  .main-content {
    padding-top: $header-height-mini;
  }
}

#container {
  //@extend .master-capper;
  height: 100%;
}
#meta_breakpoint {
  display: none;
  z-index: 0;
  @media only screen and (max-width: $small-breakpoint) {
    padding-top: $small-breakpoint;
  }
}
#meta_master_capper {
  display: none;
  z-index: 0;
  padding-top: $master-capper;
}

.page {
  margin-top: 40px;
  @include master-padding-top-bottom();
  @media only screen and (max-width: $mobile-nav-breakpoint) {
    margin-top: 0;
  }
}

.slick-arrow {
  display: none !important;
}

//nano overrides
.nano > .nano-pane {
  right: unset;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 4px;
    background-color: rgba(255, 255, 255, 0.42);
    display: block;
  }
}
.nano > .nano-pane > .nano-slider {
  border-radius: 0;
  background-color: #fff;
}
.nano > .nano-content {
  padding-bottom: 0;
}

//COOKIE
.evidon-banner {
  position: fixed !important;
  top: 0 !important;
  //bottom:0!important;
  .evidon-banner-message {
    @include font-futura-medium();
    * {
      @include font-futura-medium();
    }
  }
}

#onetrust-consent-sdk {
  font-family: "Conv_FuturaStd-Medium", sans-serif !important;
}
#onetrust-policy-text {
  text-align: center !important;
  margin: 0 auto !important;
  max-width: 1200px !important;
  float: none !important;
  * {
    font-family: "Conv_FuturaStd-Medium", sans-serif !important;
    letter-spacing: 0.02em !important;
    text-align: center !important;
  }
  font-family: "Conv_FuturaStd-Medium", sans-serif !important;
  letter-spacing: 0.1em !important;
}

.redeploy {
  opacity: 1;
}

#onetrust-banner-sdk {
  opacity: 0;
}
