body.screenings {
  #film-nav {
    display: none;
  }
  .below-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    top: 40px;
    left: 0;
    padding: 0 20px;
    max-width: 90%;
    margin: 0 auto 60px;

    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
    @media (min-width: $d) {
      flex-wrap: nowrap;
      padding: 0 65px;
    }
  }
  .sidebar {
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }
    width: 50%;
    z-index: 2;
    left: var(--offsetLeft);
    @include transition-all();
    @media only screen and (max-height: 700px) {
      padding-top: 20px;
    }

    @media (min-width: 1900px) {
      left: calc((100vw - 1920px) / 2);
    }
    .logo {
      max-width: 480px;
      display: block;
      margin: 0 auto 60px;

      @media (min-width: $d) {
        margin: 0;
      }

      a:hover,
      a:visited,
      a:link,
      a:active {
        text-decoration: none;
      }

      img {
        max-height: 150px;
        width: auto;
        margin: 0 auto;
        display: block;
        max-width: 100%;

        @media (min-width: $d) {
          width: unset;
          max-height: 250px;
          max-width: 100%;
        }
        &.above-tt {
          margin-bottom: 20px;
        }
      }
      .above-tt {
        font-family: "Futura-Book", sans-serif;
        font-weight: 700;
        color: #74c0ff;
        font-size: 3.5vw;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 20px;
        user-select: none;
        @media (min-width: 425px) {
          letter-spacing: 0.1em;
        }
        @media (min-width: 500px) {
          letter-spacing: 0.2em;
          font-size: 3vw;
        }
        @media (min-width: 550px) {
          letter-spacing: 0.3em;
          font-size: 2vw;
        }
        @media (min-width: $d) {
          font-size: 14px;
          letter-spacing: 1px;
        }
        @media (min-width: 900px) {
          font-size: 10px;
        }
        @media (min-width: 950px) {
          font-size: 14px;
        }
        @media (min-width: 1100px) {
          letter-spacing: 0.1em;
        }
        @media (min-width: 1200px) {
          letter-spacing: 0.1em;
          font-size: 16px;
        }
        @media (min-width: 1300px) {
          letter-spacing: 0.2em;
        }
        @media (min-width: 1350px) {
          letter-spacing: 7px;
        }
      }
    }

    .film-sections {
      top: 50px;

      li {
        span.line {
          position: absolute;
          top: 8px;
          left: -140px;
          display: block;
          width: 60px;
          height: 2px;
          background-color: white;
          -webkit-transition: all 350ms ease;
          -moz-transition: all 350ms ease;
          -o-transition: all 350ms ease;
          transition: all 350ms ease;
          opacity: 0;
        }

        &.active,
        &:hover {
          span.line {
            opacity: 1;
            left: -70px;
          }
        }
      }
    }
    .mini-nav-action {
      margin: 60px 0 40px;
      @media (max-height: 760px) {
        margin: 30px 0 20px;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      display: block;
      background-color: $color-teal;
      margin-bottom: 20px;
    }
  }

  .screenings-dropdown-selectors {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: -moz-calc(100% - 330px);
    width: -webkit-calc(100% - 330px);
    width: calc(100% - 330px);
    margin-left: 50px;

    .field {
      width: 50%;
      max-width: 50%;
      flex-wrap: wrap;

      &:last-child {
        margin-right: 0;
      }

      .field-box {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .arrow {
        top: 15px;
        svg {
          .cls-1 {
            stroke-width: 0;
            -webkit-transition: all 350ms ease;
            -moz-transition: all 350ms ease;
            -o-transition: all 350ms ease;
            transition: all 350ms ease;
            opacity: 1;
            fill: #fff;
          }
        }
      }
    }

    #selector-city {
      margin-top: 20px;
    }

    &.has-logo {
    }
    &.no-logo {
      max-width: 1000px;
    }

    max-width: 800px;
    @include calc(width, "100% - 530px");
    margin-left: 50px;

    @media only screen and (max-width: 1300px) {
      @include calc(width, "100% - 330px");
      margin-left: 50px;
    }
    @media only screen and (max-width: $mobile-nav-breakpoint) {
      @include calc(width, "100% - 250px");
      margin-left: 50px;
    }
    @media only screen and (max-width: $small-breakpoint) {
      margin-left: 20px;
      @include calc(width, "100% - 40px");
      display: block;
      top: 0;
      .field {
        width: 100%;
        max-width: 100%;
        margin: 0 0 0 0;
      }
    }

    .div-select-selected-display,
    .div-option {
      color: #fff;
    }
    .div-select-selected-display {
    }
  }

  .subtitle {
    h2 {
      padding: 40px 0;
      font-size: 56px;
      color: $color-blue-lt2;
      @include font-franklin-gothic-book();
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-weight: normal;
      word-break: break-word;
      @media only screen and (max-width: $small-breakpoint) {
        font-size: 40px;
        line-height: 40px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

  #all-listings {
    display: flex;
    flex-wrap: wrap;

    p {
      font-size: 20px;
      text-transform: uppercase;
      @include font-franklin-gothic-medium();
    }

    .no-screenings {
      font-size: 20px;
      color: #fff;
      font-family: Conv_ITCFranklinGothicStd-MdCd, sans-serif;
      letter-spacing: 0.1em;
    }

    .theater-row {
      margin: 0 0 40px 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      border-bottom: 1px solid $color-teal;
      padding: 40px;
      background-color: #25589a;
      width: 100%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
      &:last-child {
        border-bottom: none;
      }
      .col-theaters {
        max-width: 500px;
        margin-right: 80px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        @media only screen and (max-width: $breakpoint-small) {
          margin: 0 0 40px 0;
          width: 100%;
          .p1,
          .p2 {
          }
        }

        .location,
        .note {
          display: flex;
          width: 100%;

          .icon {
            margin-right: 10px;

            img {
              width: 50px;
              height: 50px;
            }
          }
          .copy {
            margin-top: 10px;
            margin-bottom: 30px;
            p {
              text-transform: unset;
            }

            .link a {
              color: #fff;
              font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
              letter-spacing: 0.1em;
              font-size: 22px;
              letter-spacing: 0.02em;
              text-decoration: none;
              text-transform: uppercase;
              &:hover {
                letter-spacing: 0.05em;
              }
            }
          }
        }

        h3 {
          font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
          font-size: 22px;
          letter-spacing: 0.02em;
        }

        .txt-address {
          font-family: Conv_ITCFranklinGothicStd-BkCd, sans-serif;
          letter-spacing: 0.1em;
          text-transform: unset;
          font-size: 16px;
          line-height: 22px;

          @media (min-width: $d) {
            font-size: 23px;
            line-height: 27px;
            letter-spacing: 0.03em;
          }
        }
      }
      .col-screenings {
        width: 40%;

        @media only screen and (max-width: 1300px) {
          margin-bottom: 40px;
          width: 100%;
        }

        h4 {
          // font-size: 40px;
          // line-height: 44px;
          font-size: 30px;
          // line-height: 34px;
          line-height: 1.2;
          font-weight: 300;

          @media (min-width: $d) {
            font-size: 2vw;
            letter-spacing: 0.1em;
          }
          span {
            font-weight: 700;
            &.desktop {
              display: none;
              @media (min-width: 900px) {
                display: inline;
              }
            }

            &.mobile {
              display: inline;
              @media (min-width: 900px) {
                display: none;
              }
            }
          }
        }

        p {
          color: #c3dcfd;
          font-family: Conv_FuturaStd-Bold, sans-serif;
          letter-spacing: 0.1em;
          display: block;
          font-size: 20px;
          margin: 25px 0 30px 0;

          @media (min-width: $d) {
            font-size: 1.7vw;
            margin: 10px 0 10px 0;
          }
        }

        .rsvp {
          display: block;
          align-items: center;

          @media (min-width: $d) {
            display: inline-flex;
          }
          .qaIcon {
            margin-left: 15px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            span {
              color: #fff;
              @include font-helvetica-bold();
              display: block;
              margin: 6px auto 0;
              font-size: 12px;
              line-height: 12px;
              width: 100%;
            }
            .icon {
              display: inline-flex;
              width: 18px;
              height: auto;
              img {
                width: 100%;
              }
            }
          }
          button {
            margin-top: 5px;
            padding: 10px 10px 8px;
            display: block;

            min-width: 180px;
            width: 100%;
            background-color: #0054a5;
            border: 2px solid #c3dcfd;
            letter-spacing: 0.4em;

            @media (min-width: $d) {
              width: 70%;
              max-width: 300px;
            }

            &:hover {
              background-color: #134586;
            }
          }
        }

        .item {
          margin-bottom: 50px;
        }
      }
    }
  }

  .mobile-header-dropdowns {
    display: none;
    @media only screen and (max-width: $breakpoint-mobile-nav) {
      // display: block;
    }
    padding-top: $headerSmall;
    z-index: 10;
    background-color: $color-blue-dk;
    .field-row {
      padding-bottom: 10px;
      display: block;
      .field-row-element {
        display: block;
        margin-bottom: 20px;
        width: 100%;
        .lbl {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
} //body.screenings

button.general-button {
  @include transition-all();
  background-color: $color-teal-dk2;
  border: 1px solid $color-teal;
  @include font-helvetica-bold();
  color: #fff;
  font-size: 24px;
  padding: 8px 25px;
  text-transform: uppercase;
  &.inverted {
    cursor: pointer;
    background-color: $color-teal;
    padding: 5px 17px;
    font-size: 18px;
    &:hover {
      background-color: $color-teal-dk2;
    }
  }
  &.open {
    cursor: pointer;
    &:hover {
      background-color: $color-teal;
    }
  }
  &.closed {
    cursor: no-drop;
  }
  &.error {
    border: 1px solid $color-error;
  }
} //button.general-button

#overlay-rsvp {
  display: flex;
  align-items: center;

  #rsvp-error-messages {
    * {
      color: $color-red;
    }
  }

  .rsvp-inner {
    padding: 40px;
    background-color: #4173b4;

    // @media only screen and (max-width: $breakpoint-small) {
    //   // margin: 40px 0;
    // }

    h3 {
      font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
      letter-spacing: 0.1em;
      font-size: 43px;
      color: #fff;
      display: block;
    }
  }
  .overlay-rsvp-wrapper {
    width: 100%;
    .scrim {
      position: absolute;
    }
  }
  .sec {
    margin: 20px 0;

    &.sec-driveon {
      color: #fff;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.05em;
      margin: 0;
      font-weight: 400;
      font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
      letter-spacing: 0.1em;
      letter-spacing: 0.03em;
      margin-top: 20px;

      @media (min-width: $d) {
        max-width: 45%;
      }

      .data-access {
        font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
      }

      .seating-blurb {
        color: #fff;
        font-size: 17px;
        margin: 0;
        font-weight: 400;
        margin-top: 10px;
        line-height: 19px;
        font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
        letter-spacing: 0.03em;
      }
    }
    &.sec-submit {
      margin: 20px 0;
      #submit-hook {
        border: 2px solid #c3dcfd;
        font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
        letter-spacing: 0.1em;
        font-size: 17px;
        color: #fff;
        letter-spacing: 0.2em;
        text-align: center;
        padding: 10px 13px 9px;
        display: inline-block;
        background-color: #0054a5;
        -webkit-transition: all 350ms ease;
        -moz-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;

        border: 2px solid #71a3e4;
        background-color: #71a3e4;

        font-size: 27px;
        min-width: 177px;
        padding: 9px 30px 4px;
        letter-spacing: 0.3em;

        &:hover {
          background-color: transparent;
        }
      }
    }
    &.sec-finalnote {
      margin: 30px 0 0;
      @media (min-width: $d) {
        max-width: 47%;
      }
      p {
        color: #fff;
        font-family: Conv_ITCFranklinGothicStd-BkCd, sans-serif;
        letter-spacing: 0.1em;
        font-size: 18px;
        line-height: 22px;
        margin: 7px 0;
        letter-spacing: 0.03em;

        font-family: Conv_ITCFranklinGothicStd-BkCd, sans-serif;
        font-size: 16px;
        line-height: 20px;
      }
      a {
        font-size: 16px;
      }
    }
    p {
      margin-bottom: 0;
      line-height: 1.35em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .icon {
      position: absolute;
      top: 5px;
      left: -23px;
      img {
        width: 15px;
      }
    }
  }

  .columns {
    display: flex;
    justify-content: space-between;
    // padding: 55px 55px;
    padding: 0;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      // padding: 20px 35px;
      padding: 0;
    }

    .desktop {
      display: none;
      @media (min-width: $d) {
        display: block;
      }
    }
    .mobile {
      display: block;
      @media (min-width: $d) {
        display: none;
      }
    }
    .col-left {
      width: 40%;
      min-width: 200px;
      max-width: 400px;
      margin-right: 60px;
      margin-top: -10px;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        max-width: unset;
      }

      .line {
        margin: 15px 0 15px;
        width: 100%;
        height: 1px;
        background-color: $color-teal;
        display: block;
      }
    }
    .col-right {
      width: 100%;
      .fields {
        @media only screen and (max-width: $breakpoint-small) {
          margin-top: 40px;
        }
      }
      .field-row {
        margin-bottom: 0;
        @media only screen and (max-width: $breakpoint-small) {
          display: block;
        }

        &.half-width-desktop {
          @media (min-width: $d) {
            width: 50%;
          }
        }

        .field-row-element {
          width: 100%;
          border: 1px solid #fff;
          padding: 12px 15px 12px 15px;
          background-color: #134586;
          margin: 10px 20px 20px 0;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;

          &.error {
            border: 1px solid $color-error;
          }

          input {
            width: 100%;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            color: #fff;
            font-size: 16px;
            top: 1px;
            left: 0;
            font-family: Conv_FuturaStd-Medium, sans-serif;
            letter-spacing: 0.1em;
            letter-spacing: 0.03em;
            padding: 0;
            padding: 8px 15px 9px 15px;

            &::placeholder {
              opacity: 0.5; /* Firefox */
            }

            ::-ms-input-placeholder {
              opacity: 0.5;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              background-color: transparent !important;
            }
          }
        }

        .field.field-dropdown-box {
          margin-bottom: 20px;
          box-sizing: border-box;
          .field-box {
            display: flex;

            .div-select-selected-display {
              font-family: Conv_FuturaStd-Medium, sans-serif;
              letter-spacing: 0.1em;
              letter-spacing: 0.03em;
            }

            .div-select-selected-display {
              // flex-shrink: 1;
              // height: auto;
            }

            .div-select {
              // height: auto;
              // display: flex;
              // flex-wrap: wrap;
              // flex-grow: 1;

              .div-options {
                // display: block;
                // height: auto;
                // display: flex;
                // flex-wrap: wrap;
                // flex-grow: 1;

                .div-option {
                  font-family: Conv_FuturaStd-Medium, sans-serif;
                  letter-spacing: 0.03em;
                  // height: 20px;
                  // // width: 100%;
                  // display: flex;
                  // flex-grow: 1;

                  &.hidden {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .arrow {
          top: 14px;
        }
      }

      .thanks {
        width: 100%;
        // min-height: 30vh;
        // min-height: 160px;
        margin-bottom: 40px;

        .line {
          margin: 15px 0 25px;
          width: 100%;
          height: 1px;
          background-color: $color-teal;
          display: block;
        }
        .rte {
          // text-align: center;
          // margin: 0 auto;
          h2 {
            // line-height: 0.75;
            margin-bottom: 40px;
            margin-top: 20px;
            @media (min-width: $d) {
              margin-top: -15px;
            }
          }
        }
      }

      .film-title {
        h5 {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }

  .atcb-button {
    background-color: transparent;
    color: white;
    border: none;
    box-shadow: none;
  }
} //overlay-rsvp
