// // SIZES
// $headerTall: 175px;
$headerTall: 80px;
$headerSmall: 120px;

// //COLORS
$color-teal: #3e7a9b;
$color-teal-dk: #1a6289;
$color-teal-dk2: #083856;
$color-blue: #257df9;
$color-blue-dk: #010e3a;
$color-red: #fd474f;
$color-error: $color-red;

//MAXES
$master-capper: 1800px;
$content-capper: 1400px;
$copy-capper: 800px;

//PADDINGS
$master-padding: 40px;
$master-padding-extra: 100px;

$master-padding-mobile: 20px;
$master-padding-mobile-extra: 40px;

//breakpoints
$large-breakpoint: 1400px;
// $small-breakpoint: 850px;
// $small-breakpoint-plus-1: 851px;
$small-breakpoint: 900px;
$d: 900px; // desktop breakpoint
$small-breakpoint-plus-1: 901px;
$tiny-breakpoint: 500px;

$mobile-nav-breakpoint: 950px;
$mobile-nav-breakpoint-plus-1: 951px;

$specialHeaderBreak: 525px;
$specialHeaderBreakTiny: 459px;
$specialHeaderBreakTiny2: 425px;

$responsiveLogoMidpoint: 1350px;

$film-nav-breakpoint: $small-breakpoint;

$short-screen-breakpoint: 700px;
$footer-breakpoint: 1350px;

//colors
$color-blue-vlt: #c3dcfd;
$color-blue-lt: #86b2ed;
$color-blue-lt2: #86befb;
$color-blue-lt3: #71a3e4;
$color-blue-md: #4173b4;
// $color-blue-dk: #0054a5;
$color-blue-dk: #134586;
$color-blue-dk2: #25589a;
$color-blue-dk3: #2d5fa0;
$color-blue-dk4: #134586;
$color-red: #e50914;
$color-red-lt: #e76a6a;
$color-rainbow-red: #ff8084;
$color-rainbow-orange: #ffaa80;
$color-rainbow-yellow: #ffd080;
$color-rainbow-blue: #8099ff;
$color-rainbow-violet: #ff80b9;

//margins
$header-height: 70px;
$header-height-mini: 50px;
$mobile-header-height: 20px;

$padding-under-header: 50px;
$mobile_side_padding: 20px;

@mixin font-futura-book() {
  /*
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 400;
   */
  font-family: "Conv_FuturaStd-Book", sans-serif;
  letter-spacing: 0.1em;
}
@mixin font-futura-medium() {
  /*
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 500;
   */
  font-family: "Conv_FuturaStd-Medium", sans-serif;
  letter-spacing: 0.1em;
}
@mixin font-futura-bold() {
  /*
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 700;
   */
  font-family: "Conv_FuturaStd-Bold", sans-serif;
  letter-spacing: 0.1em;
}

@mixin font-oswald-light() {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
}
@mixin font-oswald-bold() {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  letter-spacing: 0.1em;
}

@mixin font-franklin-gothic-medium() {
  font-family: "Conv_ITCFranklinGothicStd-MdCd", sans-serif;
  letter-spacing: 0.1em;
}
@mixin font-franklin-gothic-demibold() {
  font-family: "Conv_ITCFranklinGothicStd-DmCd", sans-serif;
  letter-spacing: 0.1em;
}
@mixin font-franklin-gothic-book() {
  font-family: "Conv_ITCFranklinGothicStd-BkCd", sans-serif;
  letter-spacing: 0.1em;
}

@mixin master-padding-top-bottom() {
  padding-top: $master-padding;
  padding-bottom: $master-padding;
  @media only screen and (max-width: $small-breakpoint) {
    padding-top: $master-padding-mobile;
    padding-bottom: $master-padding-mobile;
  }
}
