.link-underline-grow {
  display: inline-block;
  text-decoration: none;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    @include transition_all();
    transform-origin: top center;
  }
  &:hover {
    &:after {
      transform: scaleY(2);
    }
  }
}
.link-underline-leftright {
  cursor: pointer;
  $transTime: 250ms;
  $transEaseInOut: all $transTime ease-in-out;
  $transEaseIn: all $transTime ease-in;
  $transEaseOut: all $transTime ease-out;
  $transOff: all 0ms ease-in-out;
  color: #fff;
  transition: $transEaseInOut;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 3px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    @include calc(top, "100% - 4px");
    @include calc(left, "-100%");
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform-origin: top center;
  }
  &:before {
    transition: $transOff;
  }
  &:after {
    @include calc(left, "100%");
    transition: $transEaseOut;
  }
  &.active {
    //color:$color-cyan;
    &:before {
      @include calc(left, "-100% + 20px");
    }
    &:after {
      @include calc(left, "-100% + 20px");
    }
  }
  &:hover {
    //color:$color-cyan;
    &:before {
      left: 0;
      transition: $transEaseIn;
    }
    &:after {
      transition: $transEaseOut;
      transition-delay: $transTime;
      left: 0;
    }
  }
  &.small {
    &:before,
    &:after {
      height: 1px;
    }
  }
}
.link-underline-leftright-dark {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-red {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-white {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #fff;
  }
}

.miniButton {
  color: #000;
  @include font-helvetica-bold();
  font-size: 12px;
  text-transform: uppercase;
  background-color: #ccc;
  border: 1px solid #000;
  display: inline-block;
  margin-top: 5px;
  padding: 5px 5px 2px;
  @include transition-all();
}
button.only-mini {
  background-color: transparent;
  border: none;
  display: inline-block;
  .miniButton {
    cursor: pointer;
    margin-top: 0;
    &:hover {
      background-color: #fff;
    }
  }
}

//elipse
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-underline-grow {
  color: $color-red;
  @include font-helvetica-bold();
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    font-size: 15px;
    line-height: 15px;
  }
}

.generic-image-hover {
  cursor: pointer;
  opacity: 1;
  @include transition-opacity();
  &:hover {
    opacity: 0.85;
  }
}
.scrim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgb(26, 98, 137);
  // background: linear-gradient(
  //   180deg,
  //   rgba(26, 98, 137, 1) 0%,
  //   rgba(7, 64, 96, 1) 68%,
  //   rgba(0, 55, 85, 1) 100%
  // );
}

.mini-nav-action {
  .mini-nav-action2 {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    * {
      @include font-montserrat-bold();
      font-size: 16px;
      color: #fff;
    }
  }
  a {
    svg {
      margin-left: 5px;
      width: 12px;
      path {
        fill: #fff;
      }
      @include transition-all();
    }
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
  &.back {
    a {
      svg {
        margin-right: 5px;
        margin-left: 0;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }
}

ul.sidenav-links {
  list-style: none;
  // overflow: hidden;
  width: 340px;
  li {
    display: flex;
    // @include font-montserrat-bold();
    @include font-franklin-gothic-medium();
    font-size: 20px;
    letter-spacing: 0.1em;
    @include transition-all();
    transform: translateX(-30px);
    margin: 20px 0;
    a {
      @include transition-all();
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
      text-decoration: none;
    }
    svg {
      width: 14px;
      @include transition-all();
      opacity: 0.5;
      margin-right: 18px;
      path {
        fill: #fff;
      }
    }
    &.active,
    &:hover {
      // transform: translateX(0);
      a,
      svg {
        opacity: 1;
      }
    }
  }
}

.global-close {
  width: 40px;
  height: 40px;
  display: block;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    width: 40px;
    height: 5px;
    border-radius: 7px;
    background-color: #fff;
    position: absolute;
    top: 16px;
    left: 0;
    @include transition-all();
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before,
    &:after {
      transform: rotate(0deg);
      background-color: $color-teal;
    }
  }
}

.below-header {
  padding-top: $headerTall;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    //padding-top: $headerSmall;
    padding-top: 0;
  }
}

.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .field-row-element {
    width: 45%;
    display: flex;
    align-items: center;
    .lbl {
      color: #fff;
      @include font-montserrat-bold();
      margin-right: 15px;
    }
    input {
      padding: 10px 20px;
      @include calc(width, "100% - 40px");
      color: #fff;
      @include font-helvetica-regular();
      font-size: 18px;
      background-color: $color-teal-dk2;
      border: 1px solid $color-teal;
      @include set-input-placeholder-color(#fff);
      &.error {
        border: 1px solid $color-error;
      }
    }
    select {
      -webkit-appearance: none;
      cursor: pointer;
      padding: 10px 30px 10px 20px;
      width: 100%;
      color: #fff;
      @include font-helvetica-regular();
      background-color: $color-teal-dk2;
      border: 1px solid $color-teal;
      font-size: 18px;
      border-radius: 0;
      background-image: url(../images/global/icon-angle-down.svg);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 14px 14px;
      &.error {
        border: 1px solid $color-error;
      }
    }
  }
}

@mixin flex1() {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
//field formatting
.field-box {
  border: 1px solid #fff;
  padding: 12px 15px 12px 15px;
  background-color: $color-blue-dk4;
}
.field {
  min-width: 200px;
  @include flex1();
  margin-right: 30px;

  input,
  select,
  .div-option,
  .div-select-selected-display {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;
    &::-ms-expand {
      display: none; /* hide the default arrow in ie10 and ie11 */
    }
    background-color: transparent;
    border: 0;
    border-radius: 0;

    @include font-futura-bold();
    letter-spacing: 0.05em;
    color: #fff;
    font-size: 16px;
    @include set-input-placeholder-color(rgba(255, 255, 255, 0.51));
    top: 1px;

    left: 0;
    //top:-1px;
  }

  .div-option,
  .div-select-selected-display {
    color: rgba(255, 255, 255, 0.51);
    &.option-is-selected {
      color: #fff;
    }
  }
  .field-box {
    border: 1px solid #fff;
    padding: 12px 15px 12px 15px;
    background-color: $color-blue-dk4;
  }

  .div-select-selected-display {
    @extend .text-ellipsis;
  }
  .div-option {
    word-break: break-word;
  }

  &.error {
    .field-box {
      border: 1px solid $color-red;
      background-color: rgba(0, 0, 0, 0.66);
    }
  }
}

.field-input-box {
}
.field-dropdown-box {
  .title {
    color: $color-blue-vlt;
    opacity: 1;
    font-size: 16px;
    @include font-franklin-gothic-book();
    letter-spacing: 0.05em;
    margin: 0 0 5px 0;
  }
  .field-box {
    cursor: pointer;
    padding: 0;
    .arrow {
      position: absolute;
      top: 10px;
      left: 15px;
      width: 17px;
      height: 17px;
      .cls-1 {
        stroke-width: 0;
        @include transition_all();
        opacity: 1;
        fill: #fff;
      }
    }

    .div-select-selected-display {
      position: relative;
      padding: 13px 15px 13px 50px;
      @include calc(width, "100% - 65px");
    }

    .div-select {
      z-index: 20000;
      position: absolute;
      top: 39px;
      left: -1px;
      width: 100%;
      height: 0;
      cursor: pointer;
      overflow: auto;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      @include transition_all();
      background-color: $color-blue-dk4;
      overflow-x: hidden;
      overflow-y: auto;
      .div-option {
        padding: 15px 15px 15px 50px;
        @include calc(width, "100% - 65px");

        //MIN ONLY!!!
        @media only screen and (min-width: $small-breakpoint) {
          @include transition_all();
        }

        &:hover {
          //color: $color-red;
          background-color: $color-blue-dk4;
        }
      }
    }

    &.over {
      .arrow {
        .cls-1 {
          fill: $color-blue-lt;
        }
      }
      .div-select {
        border-bottom: 1px solid #fff;
        display: block;
        max-height: 405px; //match this to the js
        // height: 405px;
      }

      &.solid-hover {
        //background-color: rgba(0, 0, 0, 0.9);
        background-color: $color-blue-dk4;
        .div-options {
          background-color: $color-blue-dk4;
        }
        .div-option {
          //background-color: rgba(0, 0, 0, 0.9);
          &:hover {
            background-image: none;
            background-color: #fff;
            color: #000;
            //@include transition_all_timems(0ms);
          }
        }
      }
    }
  }

  &#selector-city,
  &#selector-guests {
    .field-box {
      &.over {
        &.solid-hover {
          //background-color:#000;
          .div-options {
            //background-color:#000;
          }
        }
      }
    }
  }
}
