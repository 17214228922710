#header {
  $specialHeaderBreak: 525px;
  position: fixed;
  top: 0;
  left: 0;
  height: $header-height;
  @include transition_all();
  background-color: #000;
  z-index: 100;
  @include master-min-margins();
  @media only screen and (max-width: $mobile-nav-breakpoint) {
    height: $mobile-header-height;
  }
  @media only screen and (max-width: $specialHeaderBreakTiny) {
    //padding:10px 15px 5px;
  }

  .master-capper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media only screen and (max-width: $mobile-nav-breakpoint) {
      top: 3px;
      align-items: unset;
      align-content: center;
    }
  }

  .col1 {
    width: 226px;
    @media only screen and (max-width: $mobile-nav-breakpoint) {
      width: 150px;
    }
  }
  .col3 {
    width: 84px;
    @media only screen and (max-width: $mobile-nav-breakpoint) {
      width: 75px;
    }
  }
  .col2 {
    height: 100%;
    @include calc(width, "100% - 310px");
    @media only screen and (max-width: $mobile-nav-breakpoint) {
      @include calc(width, "100% - 225px");
    }
    @media only screen and (max-width: 375px) {
      display: none;
    }
  }

  .col {
    flex: 0 1 auto;
    z-index: 2;
    &.col1 {
      text-align: left;
      .logo {
        display: inline-block;
        img {
          width: 100%;
          @include transition_all();
          @media only screen and (max-width: $specialHeaderBreakTiny2) {
            top: 1px;
          }
        }
      }
    }
    &.col2 {
      text-align: right;
      .links {
        // top: 22px;
        //margin-right:20px;

        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media only screen and (max-width: $mobile-nav-breakpoint) {
          // top: -1px;
          top: 0.5px;
          margin-right: 7px;
          text-align: center;
          //margin-right:23px;
          justify-content: center;
        }
        @media only screen and (max-width: $specialHeaderBreakTiny) {
          //margin-right:39px;
          //left:-4px;
        }
        @media only screen and (max-width: $specialHeaderBreakTiny2) {
          //left:0px;
          //margin-right:35px;
        }
        a {
          padding: 0 10px;
          @include font-franklin-gothic-book();
          color: $color-blue-lt2;
          font-size: 16px;
          text-decoration: none;
          text-transform: uppercase;
          @include transition_all();
          letter-spacing: 0.03em;
          &.txt-bold {
            @include font-franklin-gothic-demibold();
            @media only screen and (max-width: $mobile-nav-breakpoint) {
              padding: 0;
            }
          }
          &.txt {
            top: -2px;
          }

          @media only screen and (max-width: $specialHeaderBreakTiny) {
            font-size: 12px;
          }

          .icon {
            position: absolute;
            svg {
              line,
              path {
                @include transition_all();
              }
            }
          }
          &.download {
            margin-right: 20px;
            .icon {
              top: -23px;
              right: -15px;
              svg {
                width: 21px;
              }
            }
          }
          &.view {
            font-size: 26px;
            top: 2px;
            padding-right: 30px;
            @media only screen and (max-width: $mobile-nav-breakpoint) {
              font-size: 18px;
              top: -1px;
              padding-right: 0;
            }
            @media only screen and (max-width: $specialHeaderBreak) {
            }
            @media only screen and (max-width: $specialHeaderBreakTiny2) {
              //font-size:16px;
              .view-txt {
                display: none;
              }
            }
            @media only screen and (max-width: 360px) {
              display: none;
            }
            .icon {
              display: none;
              top: -10px;
              right: -40px;
              @media only screen and (max-width: $mobile-nav-breakpoint) {
                right: -32px;
                top: -12px;
              }
              @media only screen and (max-width: $specialHeaderBreak) {
                right: -32px;
                top: -12px;
              }
              @media only screen and (max-width: $specialHeaderBreakTiny) {
                //right:-32px;
                //top:-12px;
              }
              @media only screen and (max-width: $specialHeaderBreakTiny2) {
                right: -22px;
                top: -11px;
              }
              svg {
                width: 36px;
                @media only screen and (max-width: $mobile-nav-breakpoint) {
                  width: 27px;
                }
                @media only screen and (max-width: $specialHeaderBreak) {
                  width: 27px;
                }
                @media only screen and (max-width: $specialHeaderBreakTiny) {
                  //width:20px;
                }
                @media only screen and (max-width: $specialHeaderBreakTiny2) {
                  width: 20px;
                }
              }
            }
          }
          &:hover {
            color: #fff;
            line {
              stroke: #fff;
            }
            path {
              fill: #fff;
            }
          }
          @media only screen and (max-width: 1300px) {
            &.txt {
              display: none;
            }
            &.txt-bold {
            }
            &.download {
              margin-right: 50px;
            }
          }
          @media only screen and (max-width: $mobile-nav-breakpoint) {
            &.download {
              display: none;
            }
            &.view {
              line {
                stroke: #fff;
              }
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      #header3-wrapper {
        display: none;
      }
    }
    &.col3 {
      text-align: right;
      .logo-graphic {
        display: inline-block;
        @media only screen and (max-width: $mobile-nav-breakpoint) {
          display: none;
        }
        img {
          width: 84px;
          @include transition_all();
        }
      }
    }
  }

  @media only screen and (max-width: $mobile-nav-breakpoint) {
  }
}

.miniheader {
  #header {
    height: $header-height-mini;
    @media only screen and (max-width: $mobile-nav-breakpoint) {
      height: 20px;
    }
  }
}

//safari IOS offset
body.browser_safari.device_tabmobile {
  @media only screen and (max-width: $specialHeaderBreakTiny) {
    #header .col.col2 .links a.view .icon {
      //top:-13px;
    }
  }
}

body.browser_msie.device_tabmobile {
  #header .col.col2 .links a.view .icon svg {
    top: 5px;
  }
}
