.rte {
  &:not(.no-max-width) {
    max-width: $max-width-copy;
    //margin-left: auto;
    //margin-right: auto;
  }
  color: #fff;
  width: 100%;
  @include font-helvetica-regular();
  font-size: 21px;
  line-height: 1.5em;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2em;
    @include font-oswald-light();
    word-break: break-word;
    margin-bottom: 5px;
    text-transform: uppercase;
    //color:$color-dark;
  }
  h1 {
    font-size: 70px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 36px;
    }
  }
  h2 {
    font-size: 70px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 36px;
    }
  }
  h3 {
    font-size: 40px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 30px;
    }
  }
  h4 {
    @include font-oswald-medium();
    font-size: 30px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 24px;
    }
  }
  h5,
  .rte-h5 {
    font-size: 24px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }

  h6,
  p,
  a,
  li,
  a,
  tr,
  th,
  td {
    font-size: 18px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 16px;
    }

    b,
    strong {
      @include font-helvetica-bold();
    }
    i,
    em {
      font-style: italic;
    }
  }
  a {
    //top:10px;
    //color:inherit!important;
    //@extend .link-underline-leftright-dark;
    @include transition-all();
    text-decoration: underline;
    color: #fff;
    &:hover {
      color: $color-blue;
    }
  }
  p,
  h6 {
    @include font-futura-medium();
    letter-spacing: 0.02em;
    font-size: 20px;
    word-break: break-word;
    margin-bottom: 15px;
    @media only screen and (max-width: $breakpoint-small) {
      //margin-bottom: px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  b,
  strong,
  em,
  i,
  del,
  strike,
  u {
    color: inherit;
  }
  ul,
  ol {
    margin-bottom: 20px;
  }
  small {
    font-size: 14px;
  }
  figure {
    margin-bottom: 20px;
    img,
    svg {
      max-width: 100%;
    }
  }

  ul,
  ol {
    li {
      margin-bottom: 10px;
      margin-left: 17px;
    }
  }

  .important,
  .error {
    font-weight: bold;
  }

  &.tight-margins {
    p,
    li,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 5px;
    }
  }
}
