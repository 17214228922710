.film-block.categories {
  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 80px;
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
    }
    .item {
      .label {
        // @include font-oswald-medium();
        @include font-franklin-gothic-book();
        color: #fff;
        // font-size: 30px;
        font-size: 22px;
        line-height: 22px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .description {
        // @include font-helvetica-regular();
        @include font-franklin-gothic-demibold();
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        p {
          margin: 0 0 10px 0;
        }
      }
    }
  }
}
