#overlay,
.overlay-section {
  display: none;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90000000000;
  margin-top: 80px;

  .flexc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  #overlay-scrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: block;
  }

  #overlay2,
  #overlay-inner,
  .overlay-section,
  .overlay-video-wrapper,
  .overlay-video-wrapper iframe,
  .overlay-video-wrapper video {
    width: 100%;
    // height: 100%;
    height: auto;
  }
  .overlay-section {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    // background-color: #4173b4;
    display: flex;
    align-items: flex-start;
    height: auto;
  }

  .entry-logo {
    width: 100%;
    margin-bottom: 50px;
    max-width: 325px;
    img {
      width: 100%;
    }
  }

  #overlay-inner2 {
    // @include calc(width, "100% - 200px");
    // @include calc(height, "100% - 100px");
    // margin: 50px 100px;

    max-width: 1150px;
    left: -moz-calc((100% - 1150px) / 2);
    left: -webkit-calc((100% - 1150px) / 2);
    left: calc((100% - 1150px) / 2);

    @media only screen and (max-width: 1250px) {
      // @include calc(width, "100% - 40px");
      // @include calc(height, "100% - 40px");
      // margin: 20px;

      width: 90%;
      left: 5%;
    }
  }

  .overlay-video-wrapper {
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    iframe {
      aspect-ratio: 16 / 9;
    }
  }

  .overlay-video-caption {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    @include font-oswald-medium();
    font-size: 22px;
    color: $color-blue;
  }
}

.overlay-x {
  position: absolute;
  z-index: 20;
  right: 0;
  top: 0;

  .close-button {
    background-image: url("../images/global/close-btn-bg.png");
    background-size: cover;
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    overflow: hidden;
    top: -20px;
    right: -20px;
    z-index: 2;

    @media (min-width: $d) {
      width: 68px;
      height: 68px;
      top: -35px;
      left: initial;
      right: -34px;
    }

    &:before {
      content: "";
      position: absolute;

      height: 2px;
      background-color: #fff;
      -webkit-transition: all 350ms ease;
      -moz-transition: all 350ms ease;
      -o-transition: all 350ms ease;
      transition: all 350ms ease;

      top: 20px;
      left: 7px;
      width: 25px;

      @media (min-width: $d) {
        top: 34px;
        left: 14px;
        width: 40px;
      }
    }

    &:after {
      content: "";
      position: absolute;

      height: 2px;
      background-color: #fff;
      -webkit-transition: all 350ms ease;
      -moz-transition: all 350ms ease;
      -o-transition: all 350ms ease;
      transition: all 350ms ease;

      top: 20px;
      left: 7px;
      width: 25px;

      @media (min-width: $d) {
        top: 34px;
        left: 14px;
        width: 40px;
      }
    }
  }
}

// special screenings overlay
body.screenings-scroller {
  #overlay {
    position: absolute;
    #overlay-inner {
      height: auto;
    }
    #overlay-rsvp {
      display: block;
    }
  }
}

body.screenings {
  #overlay {
    .rsvp-inner {
      .col-left {
        margin-top: unset;
      }
      h3 {
        margin-bottom: 0;
      }
      .line {
        background-color: #86befb;
        width: 54%;
        height: 2px;
        display: none;
        margin-top: 0;
        @media (min-width: $d) {
          display: block;
        }
      }

      .sec {
        h5,
        p {
          color: #fff;
          font-family: Conv_ITCFranklinGothicStd-MdCd, sans-serif;
          letter-spacing: 0.1em;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.05em;
          margin: 0;
          font-weight: 400;
        }
      }

      .sec-film {
        p {
          color: #fff;
          font-family: Conv_ITCFranklinGothicStd-MdCd, sans-serif;
          letter-spacing: 0.1em;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.05em;
          margin: 0;
          font-weight: 400;
        }

        a {
          font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
          letter-spacing: 0.1em;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.05em;
          text-decoration: none;
          text-transform: uppercase;
          margin-top: 10px;
        }
      }

      .col-right {
        h5 {
          color: #fff;
          font-family: Conv_ITCFranklinGothicStd-MdCd, sans-serif;
          letter-spacing: 0.1em;
          font-size: 25px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
        }

        .film-title {
          .flim-title {
            font-family: Conv_ITCFranklinGothicStd-DmCd, sans-serif;
            letter-spacing: 0.1em;
            font-size: 22px;
            letter-spacing: 0.03em;
            color: #fff;
            text-transform: uppercase;
            margin-top: 20px;
          }
        }

        .sec-time {
          h5 {
            color: #fff;
            font-family: Conv_ITCFranklinGothicStd-MdCd, sans-serif;
            letter-spacing: 0.1em;
            font-size: 20px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          }
          p {
            color: #fff;
            font-family: Conv_ITCFranklinGothicStd-BkCd, sans-serif;
            letter-spacing: 0.1em;
            font-size: 18px;
            line-height: 22px;
            margin: 7px 0;
            letter-spacing: 0.03em;
          }
        }
        #selector-affiliation,
        #selector-guests {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;

          .div-select-selected-display {
            .field-box {
              font-family: Conv_FuturaStd-Medium, sans-serif;
              letter-spacing: 0.1em;
              letter-spacing: 0.03em;

              .div-select {
                .div-option {
                  font-family: Conv_FuturaStd-Medium, sans-serif;

                  letter-spacing: 0.03em;
                }
              }
            }
          }

          .field-row-element {
            margin: 10px 0 0px 0;
          }

          .guests-fields {
            width: 100%;
            position: relative;
            z-index: 2000;
            margin-bottom: 10px;

            @media (min-width: $d) {
              position: absolute;
              top: 80px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
