@font-face {
  font-family: "Conv_DINMittelschrift-Regular";
  src: url("/fonts/DINMittelschrift-Regular.eot");
  src: local("☺"), url("/fonts/DINMittelschrift-Regular.woff") format("woff"),
    url("/fonts/DINMittelschrift-Regular.ttf") format("truetype"),
    url("/fonts/DINMittelschrift-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura-Book";
  src: url("../fonts/FuturaStd-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@mixin font-montserrat-bold() {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
@mixin font-oswald-medium() {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}
@mixin font-oswald-light() {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}
@mixin font-helvetica-regular() {
  font-family: Arial, sans-serif;
  font-weight: 400;
}
@mixin font-helvetica-bold() {
  font-family: Arial, sans-serif;
  font-weight: 700;
}

@mixin font-akzidenz-light() {
  font-family: "akzidenz-grotesk", sans-serif;
  font-weight: 300;
}

@mixin font-akzidenz-light-italic() {
  font-family: "akzidenz-grotesk", sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin font-akzidenz-regular() {
  font-family: "akzidenz-grotesk", sans-serif;
  font-weight: 400;
}

@mixin font-akzidenz-regular-italic() {
  font-family: "akzidenz-grotesk", sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin font-akzidenz-medium() {
  font-family: "akzidenz-grotesk", sans-serif;
  font-weight: 500;
}

@mixin font-akzidenz-medium-italic() {
  font-family: "akzidenz-grotesk", sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin font-roboto-thin() {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

@mixin font-roboto-light() {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

@mixin font-helveticas-thin() {
  font-family: "Helvetica Neue LT W01_35 Thin";
}

@mixin font-helveticas-light() {
  font-family: "Helvetica Neue LT W01_41488878";
}
