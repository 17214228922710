// $colorDropdown1: $color-teal;
$colorDropdown1: white;
$colorDropdown2: $color-blue-dk;
.field-dropdown-box {
  //field formatting
  &.field {
    min-width: 200px;
    display: flex;
    align-items: center;
    // margin-right: 30px;
    margin-right: 20px;
    width: 100%;

    input,
    select,
    .div-option,
    .div-select-selected-display {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      width: 100%;
      &::-ms-expand {
        display: none; /* hide the default arrow in ie10 and ie11 */
      }
      background-color: transparent;
      border: 0;
      border-radius: 0;

      // @include font-oswald-medium();
      letter-spacing: 0.05em;
      color: #fff;
      font-size: 16px;
      @include set-input-placeholder-color(#fff);

      left: 0;
      //top:-1px;
    }

    .div-option,
    .div-select-selected-display {
      color: rgba(255, 255, 255, 0.51);
      &.option-is-selected {
        color: #fff;
      }
    }
    .field-box {
      // border: 1px solid $colorDropdown1;
      padding: 8px 15px 9px 15px;
      background-color: $colorDropdown2;
      width: 100%;

      &.error {
        border: 1px solid $color-error;

        &:hover {
          .div-select {
            border: 1px solid $color-error;
            border-top: 1px solid transparent;
          }
        }
      }
    }

    .div-select-selected-display {
      @extend .text-ellipsis;
    }
    .div-option {
      word-break: break-word;
    }

    &.error {
      .field-box {
        //border: 1px solid rgb(40, 104, 205);
        border: 1px solid rgba(255, 255, 0, 1);
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }

  .title {
    // color: #fff;
    // opacity: 1;
    // font-size: 14px;
    // // @include font-montserrat-bold();
    // letter-spacing: 0.05em;
    // margin: 0 0 5px 0;
  }
  .field-box {
    cursor: pointer;
    padding: 0;
    border: 1px solid #fff;
    padding: 12px 15px 12px 15px;
    background-color: #134586;

    .arrow {
      position: absolute;
      top: 8px;
      left: 15px;
      width: 17px;
      height: 17px;
      .cls-1 {
        stroke-width: 0;
        @include transition_all();
        opacity: 1;
        fill: #fff;
      }
    }

    .div-select-selected-display {
      position: relative;
      // padding: 10px 15px 11px 50px;
      padding: 10px 15px 11px 30px;
      @include calc(width, "100% - 65px");
    }

    .div-select {
      z-index: 20000;
      position: absolute;
      top: 40px;
      left: -1px;
      width: 100%;
      height: 0;
      cursor: pointer;
      overflow: auto;
      border-left: 1px solid $colorDropdown1;
      border-right: 1px solid $colorDropdown1;
      @include transition_all();
      background-color: $colorDropdown2;
      overflow-x: hidden;
      overflow-y: auto;

      object-fit: cover;

      .div-option {
        padding: 15px 15px 15px 50px;
        @include calc(width, "100% - 65px");

        //MIN ONLY!!!
        @media only screen and (min-width: $breakpoint-small) {
          @include transition_all();
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.33);
        }
      }
    }

    &.over {
      .arrow {
        .cls-1 {
          fill: #fff;
        }
      }
      .div-select {
        border-bottom: 1px solid $colorDropdown1;
        display: block;
        max-height: 405px; //match this to the js
        // height: 405px;
      }

      &.solid-hover {
        //background-color: rgba(0, 0, 0, 0.9);
        // background-image: url("../images/shared/bg-dropdown-affiliation.png");
        // background-size: cover;
        .div-options {
          // background-image: url("../images/shared/bg-dropdown-affiliation.png");
          // background-size: cover;
        }
        .div-option {
          //background-color: rgba(0, 0, 0, 0.9);
          // font-family: Conv_FuturaStd-Medium, sans-serif;
          // letter-spacing: 0.1em;
          // letter-spacing: 0.03em;

          &:hover {
            background-image: none;
            background-color: #fff;
            color: #000;
            @include transition_all_timems(0ms);
          }
        }
      }
    }
  }

  &#selector-city,
  &#selector-guests {
    .field-box {
      &.over {
        // &.solid-hover {
        //   background-image: url("../images/shared/bg-dropdown-guest.png");
        //   background-size: cover;
        //   .div-options {
        //     background-image: url("../images/shared/bg-dropdown-guest.png");
        //     background-size: cover;
        //   }
        // }
      }
    }
  }
}
