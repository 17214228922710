// //MAXES
// $master-capper:1800px;
// $content-capper:1400px;
// $copy-capper:800px;

// //PADDINGS
// $master-padding:40px;
// $master-padding-extra:100px;

// $master-padding-mobile:20px;
// $master-padding-mobile-extra:40px;

.master-capper {
  margin-left: auto;
  margin-right: auto;
  max-width: $master-capper;
}
.content-capper {
  margin-left: auto;
  margin-right: auto;
  max-width: $content-capper;
}
.copy-capper {
  margin-left: auto;
  margin-right: auto;
  max-width: $copy-capper;
}

//
@mixin master-min-margins() {
  padding: 0 25px;
  @include calc(width, "100% - 50px");
  @media only screen and (max-width: $mobile-nav-breakpoint) {
    padding: 15px;
    @include calc(width, "100% - 30px");
  }
}

//boxes
@mixin master-padding-surround-innerbox() {
  padding: 30px;
  @media only screen and (max-width: $small-breakpoint) {
    padding: 20px;
  }
}

//left right, top bottom
@mixin master-margin-left-right-width() {
  margin-left: $master-padding;
  margin-right: $master-padding;
  @include calc(width, "100% - 80px");
  @media only screen and (max-width: $small-breakpoint) {
    margin-left: $master-padding-mobile;
    margin-right: $master-padding-mobile;
    @include calc(width, "100% - 40px");
  }
}
@mixin master-margin-left-right-width-extra() {
  margin-left: $master-padding-extra;
  margin-right: $master-padding-extra;
  @include calc(width, "100% - 200px");
  @media only screen and (max-width: $small-breakpoint) {
    margin-left: $master-padding-mobile-extra;
    margin-right: $master-padding-mobile-extra;
    @include calc(width, "100% - 80px");
  }
}
@mixin master-margin-left-extra() {
  margin-left: $master-padding-extra;
  @include calc(width, "100% - 100px");
  @media only screen and (max-width: $small-breakpoint) {
    margin-left: $master-padding-mobile-extra;
    @include calc(width, "100% - 40px");
  }
}
// @mixin master-padding-top-bottom() {
//   padding-top: $master-padding;
//   padding-bottom: $master-padding;
//   @media only screen and (max-width: $small-breakpoint) {
//     padding-top: $master-padding-mobile;
//     padding-bottom: $master-padding-mobile;
//   }
// }
@mixin master-margin-top-bottom() {
  margin-top: $master-padding;
  margin-bottom: $master-padding;
  @media only screen and (max-width: $small-breakpoint) {
    margin-top: $master-padding-mobile;
    margin-bottom: $master-padding-mobile;
  }
}

.generic-content {
  @include master-margin-left-right-width-extra();
}
