body.film {
  $heightBreak1: 700px;
  .site-content {
    padding-top: 60px;
  }
  .content-lr {
    display: flex;
    justify-content: flex-end;
    // padding-top: $headerTall;
    // padding-top: 70px;
    .film-blocks {
      width: 55%;
      margin-top: 120px;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        margin-top: 90px;
      }
      .film-blocks2 {
        min-height: calc(100vh - 500px);
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      padding-top: 0;
    }
  }
  .sidebar {
    @media only screen and (max-width: $breakpoint-small) {
      // display: none;
      width: 100%;
      position: relative;
      top: 30px;
    }
    max-width: 550px;
    width: 40%;

    z-index: 2;
    position: fixed;
    left: var(--offsetLeft);
    // top: $headerTall;
    top: 60px;
    @include transition-all();
    padding-top: 60px;
    @media only screen and (max-height: $heightBreak1) {
      padding-top: 40px;
    }

    @media (min-width: 1900px) {
      left: calc((100vw - 1920px) / 2);
    }

    .capper-padded {
      // padding-left: 40px;
      width: calc(100% - 50px);
      @media (min-width: 900px) {
        // padding-left: 40px;
        padding-left: 60px;
        width: calc(100% - 60px);
      }
    }
    .logo {
      // width: 75%;
      img {
        display: block;
        margin: 0 auto;
        width: 80%;
        @media (min-width: 900px) {
          width: 100%;
        }

        &.above-tt {
          margin-bottom: 20px;
        }
      }

      .above-tt {
        font-family: "Futura-Book", sans-serif;
        font-weight: 700;
        color: #74c0ff;
        font-size: 3.5vw;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 20px;
        user-select: none;
        @media (min-width: 425px) {
          font-size: 16px;
          letter-spacing: 0.1em;
        }
        @media (min-width: 500px) {
          letter-spacing: 0.2em;
        }
        @media (min-width: 550px) {
          letter-spacing: 0.3em;
        }
        @media (min-width: 900px) {
          letter-spacing: 0em;
        }
        @media (min-width: 960px) {
          letter-spacing: 0.1em;
        }
        @media (min-width: 1100px) {
          letter-spacing: 0.2em;
        }
        @media (min-width: 1200px) {
          letter-spacing: 0.3em;
        }
        @media (min-width: 1300px) {
          letter-spacing: 0.4em;
        }
        @media (min-width: 1350px) {
          letter-spacing: 7px;
        }
      }
    }

    .film-sections {
      @media only screen and (max-width: $breakpoint-small) {
        display: none;
      }
      top: 50px;
      left: 60px;

      li {
        span.line {
          position: absolute;
          top: 8px;
          left: -140px;
          display: block;
          width: 60px;
          height: 2px;
          background-color: white;
          -webkit-transition: all 350ms ease;
          -moz-transition: all 350ms ease;
          -o-transition: all 350ms ease;
          transition: all 350ms ease;
          opacity: 0;
        }

        &.active,
        &:hover {
          span.line {
            opacity: 1;
            // left: -70px;
            left: -102%;
            width: 100%;
          }
        }
      }
    }
    .mini-nav-action {
      margin: 60px 0 40px;
      @media (max-height: 760px) {
        margin: 30px 0 20px;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      display: block;
      background-color: $color-teal;
      margin-bottom: 20px;
    }
  }

  .film-blocks {
    .film-block {
      // padding-top: $headerTall;
      padding-top: 0;
      // &.synopsis {
      //   padding-top: 0;
      // }
      //margin-bottom: 20vh;
      @media only screen and (max-width: $breakpoint-small) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $color-teal;
      }
      .rte {
        h2 {
          @media only screen and (max-width: $breakpoint-small) {
            font-size: 30px;
            line-height: 30px;
          }
          @include font-franklin-gothic-book();
          font-size: 56px;
          margin-bottom: 50px;
          color: $color-blue-lt;
        }
      }
    }
    @media only screen and (max-height: $heightBreak1) {
      .fil-blocks2 {
        margin-top: -15px;
      }
    }
  }

  .mobile-topbar {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      margin-top: $headerSmall;
      .fyc-label {
        margin: 40px 0 20px;
        text-transform: uppercase;
        font-size: 24px;
        @include font-oswald-light();
        color: #b9b9b9;
      }
    }
  }
}
